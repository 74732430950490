import { startOfMonth, subDays, parse } from "date-fns";

const DateDefaults = {
  formats: {
    CLIENT: "yyyy-MM-dd",
    COMPAT: "yyyyMMdd",
    ISO_8601: "yyyy-MM-dd'T'HH:mm:ss.SSSXXX",
    VIEW: "dd-MM-yyyy",
    LOG: "dd/MM/yy HH:mm:ss",
    ONLY_DATE: "dd/MM/yyyy",
    ONLY_DAY: "dd",
    ONLY_DAY_AND_MONTH: "dd/MM",
    ONLY_HOUR: "HH:mm:ss",
    ONLY_HOUR_AND_MINUTE: "HH:mm",
    COMPAT_MESE: "LLL",
    MESE_ANNO: "MMMM yyyy",
    NUMBER_MONTH_YEAR: "yyyy-MM",
    ONLY_YEAR_SHORT: "yy",
    ANNO: "yyyy",
    UNIX_EPOCH: "T",
  },

  MESI: [
    "Gennaio",
    "Febbraio",
    "Marzo",
    "Aprile",
    "Maggio",
    "Giugno",
    "Luglio",
    "Agosto",
    "Settembre",
    "Ottobre",
    "Novembre",
    "Dicembre",
  ],

  START_TIME_DAY: "00:00:00",
  START_TIME_DAY_NO_SECONDS: "00:00",
  END_TIME_DAY: "23:59:59",
  END_TIME_DAY_NO_SECONDS: "23:59",

  DATA_INIZIO: new Date(2021, 1, 1),
  PRIMO_DEL_MESE: startOfMonth(new Date()),
  OGGI: new Date(),
  UN_MESE_FA: subDays(new Date(), 30),
  parseWithFormat: (d, f) => parse(d, f, new Date()),
  parseAnnoMeseGiorno: (d) => parse(d, DateDefaults.formats.CLIENT, new Date()),
};

export default DateDefaults;
