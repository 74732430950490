<template>
  <div id="app">
    <LoaderMida :showLoader="true" />
    <router-view />
  </div>
</template>

<script>
import _ from "lodash";
import LoaderMida from "@/components/_common/LoaderMida";
import { mapActions, mapState } from "vuex";
import { _err } from "@/entities/log";
import { AlertType } from "@/entities/alert";
import { checkVersion } from "@/checkVersion";
import conf from "@/../package.json";
const UPDATE_CHECK_TIMER = 3 * 60 * 60 * 1000;
const CURRENT_VERSION = conf.version;
const channel = new BroadcastChannel("sw-messages");

export default {
  components: {
    LoaderMida,
  },

  data() {
    return {};
  },
  computed: {
    ...mapState({
      axiosError: (s) => s.axiosError,
    }),
  },
  async created() {
    // this.checkUpdatesLoop();
    channel.onmessage = (msg) => {
      if (msg && msg.data === "UPDATE_AVAILABLE") {
        this.updateAvailable();
      }
    };
    document.addEventListener("checkUpdates", async () => {
      if (await checkVersion(CURRENT_VERSION)) {
        channel.postMessage("UPDATE_AVAILABLE");
        this.updateAvailable();
      }
    });
    window.addEventListener("resize", this.setWindowWidth);
  },
  destroyed() {
    window.removeEventListener("resize", this.setWindowWidth);
  },

  methods: {
    ...mapActions({
      setAlert: "setAlert",
      setWindowWidthPropertyVuex: "setWindowWidth",
      setAxiosError: "setAxiosError",
      setUpdateAvailable: "setUpdateAvailable",
    }),
    checkUpdatesLoop() {
      setTimeout(() => {
        document.dispatchEvent(new CustomEvent("checkUpdates"));
        this.checkUpdatesLoop();
      }, UPDATE_CHECK_TIMER);
    },
    updateAvailable() {
      // console.log("chiamato _updateAvailable");
      this.$nextTick(async () => {
        await this.setAlert({
          type: AlertType.INFO,
          msg: "Rilevata nuova versione: aggiornamento in corso...",
          duration: 2 * 1000,
          persistent: true,
          login: true,
        });
        location.reload(true);
      });
    },
    setWindowWidth() {
      this.setWindowWidthPropertyVuex(window.innerWidth);
    },
  },

  watch: {
    "$api.errore"(err) {
      this.setAxiosError(_err("watch $api.errore", err));
    },
    axiosError(val) {
      // console.log("app watcher axios", val);
      if (!_.isNil(val)) {
        this.setAlert({
          type: AlertType.ERROR,
          msg: val,
          // duration: 2 * 1000,
          persistent: false,
          login: false,
        });
      }
    },
  },
};
</script>
<style lang="scss">
#app {
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;

  .main-section {
    margin: 0px 15px;
  }

  .container {
    max-width: none;
    .row {
      margin: 0;
      .col {
        padding: 0;
      }
    }
  }

  .v-data-table {
    border: 1px solid #cad7e9;
    margin-top: 12px;
  }
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > thead
    > tr
    > th {
    color: rgba(0, 0, 0, 0.87);
  }

  .v-dialog {
    margin: 0;
    padding: 0;
    overflow-y: visible;
    max-width: 500px;

    header {
      .v-toolbar__content {
        padding: 2px 16px;
      }
    }
  }

  .v-card {
    .v-card__text {
      padding-bottom: 9px;
    }
    .v-card__actions {
      padding-top: 0;
    }

    .reduce-padding {
      padding: 4px 12px;
    }
    .v-text-field.v-text-field--solo .v-input__control {
      min-height: 0;
    }
    .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
      > .v-input__control
      > .v-input__slot {
      padding: 0;
    }
    .v-input__slot {
      margin-bottom: 0px;
    }

    .v-text-field__details {
      display: none;
    }
    .v-messages {
      display: none;
    }
    .mostra-messaggio-errore.error--text {
      .v-text-field__details {
        display: initial;
        margin-top: 0.2rem;
      }
      div.v-messages {
        display: initial;
      }
    }
  }

  div input[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
    display: none;
  }

  .v-btn {
    color: var(--color-button-default);
  }
  .theme--dark.v-btn,
  .theme--dark.v-icon {
    color: var(--color-icon-theme-dark);
  }
}
</style>
