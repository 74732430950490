import _ from "lodash";
import store from "@/store";

/**
 * Permette di mandare a log il valore passato e lo restituisce<br/>
 * accetta 1 o 2 parametri, se sono 2 li passa entrambi<br/>
 * a console.log applicando JSON.stringify al secondo<br/>
 * e restituisce il secondo<br/>
 *
 * PER FERMARE I LOG ENTRARE NEL FILE E MODIFICARE LA COSTANTE STOP
 * O UTILIZZARE L'ARRAY EXCLUDED
 *
 * es:<br/>
 * _l(1) // mostra "1" in console e restituisce 1<br/>
 * _l("test", 1) mostra "test 1" in console e restituisce 1<br/>
 *
 * @returns object
 */

// PER BLOCCARE I LOG
const STOP = false;
const EXCLUDED = [
  //"Login",
  "Abilitazioni",
].includes(store.activeSection);

function _check(args) {
  let ret = true;
  if (![1, 2].includes(args.length)) {
    console.warn(
      `La funzione accetta 1 o 2 parametri${
        args.length > 0 ? ", altrimenti si comporta come console.log" : ""
      }`
    );
    console.log(...args);
    ret = false;
  }
  return ret;
}

function _out(fn, args) {
  if (STOP || EXCLUDED) {
    return arguments.length === 1 ? arguments[0] : arguments[1];
  }

  if (Array.isArray(args[args.length - 1])) {
    args = [args[args.length - 1]];
    fn = console.table;
  }
  return args.length === 1
    ? _.tap(args[0], (val) => fn.call(null, val))
    : _.tap(args[1], (val) => fn.call(null, args[0], val));
}

export function _l() {
  if (_check([...arguments])) return _out(console.log, [...arguments]);
}

export function _err() {
  if (_check([...arguments])) return _out(console.error, [...arguments]);
}
